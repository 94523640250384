import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://27b68808c6ec440d39eb2d73e07eecd9@o4507089095229440.ingest.us.sentry.io/4507089116790784",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1,
});